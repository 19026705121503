import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { formatDateToDDMMYYYY } from "../../../utils/date_formatter";
import { figureFormatter } from "../../../utils/figure_formatter";

interface IDetailsInfo {
  entityDetails: any;
}

const PhysicalAssetReversal: FC<IDetailsInfo> = ({ entityDetails }) => {
  return (
    <Card>
      <CardContent>
        <Typography
          sx={{ fontSize: 14, mb: 1.5, fontWeight: "bold" }}
          gutterBottom
        >
          Physical Asset Details
        </Typography>

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Asset Name:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {entityDetails?.name}
        </Typography>

        {entityDetails?.purchase_price && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Purchase Price:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {figureFormatter({ figure: entityDetails?.purchase_price })}
            </Typography>
          </>
        )}

        {entityDetails?.payment_method && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Payment Method:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.payment_method}
            </Typography>
          </>
        )}

        {entityDetails?.condition_display && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Condition:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.condition_display}
            </Typography>
          </>
        )}

        {entityDetails?.serial_number && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Serial Number:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.serial_number}
            </Typography>
          </>
        )}

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Asset Type:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {entityDetails?.type_display}
        </Typography>

        {entityDetails?.location && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Location:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.location}
            </Typography>
          </>
        )}

        {entityDetails?.branch_details && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Branch:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.branch_details?.name}
            </Typography>
          </>
        )}

        {entityDetails?.purchase_date && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Purchase Date:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {formatDateToDDMMYYYY(entityDetails?.purchase_date)}
            </Typography>
          </>
        )}

        {entityDetails?.current_value && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Current Value:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {figureFormatter({ figure: entityDetails?.current_value })}
            </Typography>
          </>
        )}

        {entityDetails?.comments && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Notes:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.comments}
            </Typography>
          </>
        )}

        {entityDetails?.warranty_provider_name && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Warranty Provider Name:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.warranty_provider_name}
            </Typography>
          </>
        )}

        {entityDetails?.warranty_provider_email && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Warranty Provider Email:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.warranty_provider_email}
            </Typography>
          </>
        )}

        {entityDetails?.warranty_provider_phone_number && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Warranty Provider Phone No:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.warranty_provider_phone_number}
            </Typography>
          </>
        )}

        {entityDetails?.warranty_coverage_period && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Warranty Coverage Period:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.warranty_coverage_period}
            </Typography>
          </>
        )}

        {entityDetails?.warranty_coverage_period_start_date && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Warranty Coverage Period Start Date:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.warranty_coverage_period_start_date}
            </Typography>
          </>
        )}

        {entityDetails?.warranty_coverage_period_type && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Warranty Coverage Period Type:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.warranty_coverage_period_type}
            </Typography>
          </>
        )}

        {entityDetails?.depreciates !== null && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Depreciates:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.depreciates === true ? "Yes" : "No"}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default PhysicalAssetReversal;
