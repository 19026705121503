import { FC, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  CircularProgress,
  useTheme,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  "& .MuiDialog-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .MuiDialog-paper": {
    width: "350px",
    maxWidth: "95vw",
    minHeight: "150px",
    padding: "10px",
    borderRadius: "8px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
    margin: 0,
    backgroundColor: "#fff",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "320px",
      minHeight: "140px",
      padding: "8px",
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: "8px 24px",
  fontSize: "14px",
  fontWeight: 500,
  textTransform: "none",
  width: "100%",
  backgroundColor: "#1B5E20",
  "&:hover": {
    backgroundColor: "#2E7D32",
  },
  "&.Mui-disabled": {
    backgroundColor: "#81C784",
    color: "#fff",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "6px 16px",
    fontSize: "13px",
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 8,
  top: 8,
  color: theme.palette.grey[500],
  padding: "4px",
  zIndex: 1,
  "&:hover": {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[200],
  },
}));

interface SessionMonitorProps {
  open: boolean;
  onStayLoggedIn: () => Promise<boolean>;
  onLogout: () => void;
  initialSeconds: number;
  onClose: () => void;
}

const SessionMonitor: FC<SessionMonitorProps> = ({
  open,
  onStayLoggedIn,
  onLogout,
  initialSeconds,
}) => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [ignoreClose, setIgnoreClose] = useState(false);
  const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const theme = useTheme();

  useEffect(() => {
    setSeconds(initialSeconds);
  }, [initialSeconds]);

  useEffect(() => {
    if (open) {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }

      timerRef.current = setInterval(() => {
        setSeconds((prev) => {
          const next = Math.max(0, prev - 1);
          if (next === 0) {
            clearInterval(timerRef.current!);
            onLogout();
          }
          return next;
        });
      }, 1000);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [open, onLogout]);

  const handleStayLoggedIn = async () => {
    setIsRefreshing(true);
    try {
      const success = await onStayLoggedIn();
      if (!success) {
        onLogout();
      }
    } catch (error) {
      onLogout();
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleClose = () => {
    setIgnoreClose(true);
  };

  const formatTime = (
    totalSeconds: number,
  ): { minutes: number; seconds: number } => {
    const minutes = Math.floor(Math.max(0, totalSeconds) / 60);
    const remainingSeconds = Math.max(0, totalSeconds) % 60;
    return { minutes, seconds: remainingSeconds };
  };

  const { minutes, seconds: remainingSeconds } = formatTime(seconds);

  return (
    <StyledDialog
      open={open && !ignoreClose}
      onClose={handleClose}
      fullWidth={false}
      maxWidth="xs"
    >
      <CloseButton onClick={handleClose} aria-label="close">
        <CloseIcon />
      </CloseButton>
      <DialogContent>
        <Box
          sx={{
            textAlign: "center",
            mb: 2,
            [theme.breakpoints.down("sm")]: { mb: 1.5 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mb: 1.5,
            }}
          >
            <ErrorOutlineIcon
              sx={{
                color: "#d32f2f",
                fontSize: { xs: "30px", sm: "38px" },
                mb: 1.5,
              }}
            />
            <Typography
              variant="h6"
              sx={{
                fontWeight: 500,
                fontSize: "18px",
                color: "rgba(0, 0, 0, 0.87)",
                textAlign: "center",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "15px",
                },
              }}
            >
              Session Timeout!
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: "14px",
              color: "rgba(0, 0, 0, 0.67)",
              mb: 2,
              [theme.breakpoints.down("sm")]: {
                fontSize: "13px",
                mb: 1.5,
              },
            }}
          >
            {" "}
            <Box component="span" sx={{ color: "#d32f2f", fontWeight: 600 }}>
              {minutes} mins {remainingSeconds.toString().padStart(2, "0")} secs
            </Box>
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <StyledButton
            variant="contained"
            onClick={handleStayLoggedIn}
            disabled={isRefreshing}
          >
            Stay logged in
          </StyledButton>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default SessionMonitor;
