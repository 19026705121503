import * as React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { AlertTitle } from "@mui/material";

interface AlertMessageProps {
  title?: string;
  type: "success" | "error" | "warning" | "info";
  message: string | React.ReactNode;
  isOpen: boolean;
  closeAlert?: () => void;
  extraStyles?: any;
  showAction?: boolean;
}

const getAlertTitle = (type: string) => {
  switch (type) {
    case "success":
      return "Success";
    case "error":
      return "Error";
    case "warning":
      return "Warning";
    case "info":
      return "Info";
    default:
      return "Error";
  }
};

export const AlertMessage: React.FC<AlertMessageProps> = ({
  title,
  type,
  message,
  isOpen = false,
  closeAlert,
  extraStyles,
  showAction = true,
}: AlertMessageProps) => {
  const [open, setOpen] = React.useState(isOpen);

  return (
    <Box sx={{ width: "100%" }}>
      <Collapse in={open}>
        <Alert
          action={
            showAction && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  closeAlert && closeAlert();
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )
          }
          sx={[styles, extraStyles?.alertBaner]}
          severity={type}
        >
          <AlertTitle sx={[{ fontWeight: "bold" }, extraStyles?.alertMessage]}>
            {title || getAlertTitle(type)}
          </AlertTitle>
          {message}
        </Alert>
      </Collapse>
    </Box>
  );
};

const styles = {
  marginBottom: 2,
  minWidth: "0px !important",
};
