import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPaginationState {
  data: any;
  filters: Record<string, string | number>;
  searchQuery: string;
  totals: any;
}

const initialState: IPaginationState = {
  data: [],
  filters: {
    period: "last_30_days",
  },
  searchQuery: "",
  totals: {},
};

const PaginationSlice = createSlice({
  name: "ReferralTrackingPagination",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<{ data: any; totals: any }>) => {
      const { data, totals } = action.payload;
      state.data = data;
      state.totals = totals;
    },
    setFilters: (
      state,
      action: PayloadAction<Record<string, string | number>>,
    ) => {
      state.filters = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
  },
});

export const { setData, setFilters, setSearchQuery } = PaginationSlice.actions;

export const selectPagination = (state: any) =>
  state.ReferralTrackingPagination;

export default PaginationSlice.reducer;
