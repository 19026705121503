import React, { FC, useState } from "react";
import { H6, Span } from "../../../Typography";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FlexBox from "../../../FlexBox";
import { ukoTheme } from "../../../../theme";
import CollapsibleSection from "../CollapsibleSection";
import { AlertMessage } from "../../AlertMessage";
import { isEmpty } from "lodash";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface ResultsCreditScoreProps {
  data: any;
}

const ResultsCreditScore: FC<ResultsCreditScoreProps> = (props) => {
  const theme = ukoTheme();
  const [showDetails, setShowDetails] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<any>("");
  const [selectedProfileDetails, setSelectedProfileDetails] = useState<any>({});

  const hasNoProfile = () => {
    return props?.data?.status === 5 || isEmpty(props?.data?.result);
  };

  const hasMultipleProfiles = () => {
    return String(props?.data?.parameter?.type || "CRB").includes(".");
  };

  const handleGetProfilesList = () => {
    return String(props?.data?.parameter?.type || "CRB").split(".");
  };

  const handleGetProfileDetails = async (profileType: string) => {
    setShowDetails(true);
    setSelectedProfile(profileType);
    setSelectedProfileDetails(props?.data?.result[profileType]);
  };

  const handleBack = () => {
    setShowDetails(false);
    setSelectedProfile({});
  };

  const getShowDetails = () => {
    return hasMultipleProfiles() ? showDetails : true;
  };

  const scoreResult = hasMultipleProfiles()
    ? selectedProfileDetails
    : props?.data?.result[props?.data?.parameter?.type || "CRB"];

  return (
    <Box sx={{ width: "100%" }}>
      {hasNoProfile() && (
        <Box>
          <AlertMessage
            isOpen={true}
            message={"We could not find any credit score for this client."}
            type="error"
            closeAlert={() => {}}
          />
        </Box>
      )}

      {scoreResult && getShowDetails() ? (
        <Box sx={{ paddingBottom: 5 }}>
          <FlexBox
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={2}
          >
            <H6 sx={styles.profileTitle}>{`${selectedProfile || ""} Score`}</H6>
            <IconButton onClick={handleBack} aria-label="delete" size="small">
              <ArrowBackIcon fontSize="inherit" />
            </IconButton>
          </FlexBox>

          <Box sx={styles.container} marginBottom={2}>
            <FlexBox gap={2} alignItems={"flex-start"}>
              <Box sx={styles.iconCircle}>
                {scoreResult?.Customer.Photo ? (
                  <Avatar
                    alt={scoreResult?.Customer?.Name}
                    src={scoreResult?.Customer.Photo}
                  />
                ) : (
                  <AccountCircleIcon
                    color="primary"
                    sx={{ height: "3.8rem", width: "3.8rem" }}
                  />
                )}
              </Box>
              <FlexBox flexDirection={"column"} gap={0.8}>
                <Span sx={styles.labelValue}>
                  <Span color={theme.palette.common.black}>Category:</Span>
                  {scoreResult?.Customer?.Category}
                </Span>
                <Span sx={styles.labelValue}>
                  <Span color={theme.palette.common.black}>Name:</Span>
                  {scoreResult?.Customer?.Name}
                </Span>
                <Span sx={styles.labelValue}>
                  <Span color={theme.palette.common.black}>Age:</Span>
                  {scoreResult?.Customer?.Age}
                </Span>
                <Span sx={styles.labelValue}>
                  <Span color={theme.palette.common.black}>Date of Birth:</Span>{" "}
                  {scoreResult?.Customer?.Date_of_Birth}
                </Span>
                <Span sx={styles.labelValue}>
                  <Span color={theme.palette.common.black}>Phone Number:</Span>{" "}
                  {scoreResult?.Customer?.Phone}
                </Span>
              </FlexBox>
            </FlexBox>
          </Box>

          <Box marginBottom={2}>
            <CollapsibleSection title="Scoring">
              <Box
                sx={[
                  styles.container,
                  {
                    marginX: "-1rem",
                    marginBottom: "-1rem",
                    transition: "all 0.3s",
                  },
                ]}
              >
                <Grid container rowSpacing={2.5}>
                  <Grid item xs={6}>
                    <H6 marginBottom={0} sx={styles.vlabelText}>
                      Period
                    </H6>
                    <Span sx={styles.vlabelValue}>
                      {scoreResult?.Scoring?.Period || "---"}
                    </Span>
                  </Grid>
                  <Grid item xs={6}>
                    <H6 marginBottom={0} sx={styles.vlabelText}>
                      Score
                    </H6>
                    <Span sx={styles.vlabelValue}>
                      {scoreResult?.Scoring?.Score || "---"}
                    </Span>
                  </Grid>
                  <Grid item xs={6}>
                    <H6 marginBottom={0} sx={styles.vlabelText}>
                      Band
                    </H6>
                    <Span sx={styles.vlabelValue}>
                      UGX
                      {scoreResult?.Scoring?.Band || "---"}
                    </Span>
                  </Grid>
                  <Grid item xs={6}>
                    <H6 marginBottom={0} sx={styles.vlabelText}>
                      Rating
                    </H6>
                    <Span sx={styles.vlabelValue}>
                      {scoreResult?.Scoring?.Rating || "---"}
                    </Span>
                  </Grid>
                  <Grid item xs={6}>
                    <H6 marginBottom={0} sx={styles.vlabelText}>
                      Probability of Default percent
                    </H6>
                    <Span sx={styles.vlabelValue}>
                      {scoreResult?.Scoring?.Probability_of_Default_Percent ||
                        "---"}
                    </Span>
                  </Grid>
                  <Grid item xs={6}>
                    <H6 marginBottom={0} sx={styles.vlabelText}>
                      Likelihood to default
                    </H6>
                    <Span sx={styles.vlabelValue}>
                      {scoreResult?.Scoring?.Likelihood_to_Default || "---"}
                    </Span>
                  </Grid>
                  <Grid item xs={6}>
                    <H6 marginBottom={0} sx={styles.vlabelText}>
                      Previous Score
                    </H6>
                    <Span sx={styles.vlabelValue}>
                      {scoreResult?.Scoring?.Previous_Score || "---"}
                    </Span>
                  </Grid>
                </Grid>
              </Box>
            </CollapsibleSection>
          </Box>

          <Box marginBottom={2}>
            <CollapsibleSection title="Credit Applications">
              <Box
                sx={[
                  styles.container,
                  {
                    marginX: "-1rem",
                    marginBottom: "-1rem",
                    transition: "all 0.3s",
                  },
                ]}
              >
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Box sx={styles.whiteCard}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Total Applications (0)
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        UGX
                        {scoreResult?.Credit_Applications?.Total_Applications ||
                          "---"}
                      </Span>
                    </Box>
                  </Grid>

                  <Grid item xs={4}>
                    <Box sx={styles.whiteCard}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Applications_12 Months (0)
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        UGX
                        {scoreResult?.Credit_Applications
                          ?.Applications_12Months || "---"}
                      </Span>
                    </Box>
                  </Grid>

                  <Grid item xs={4}>
                    <Box sx={styles.whiteCard}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Pending Applications (0)
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        UGX
                        {scoreResult?.Credit_Applications
                          ?.Pending_Applications || "---"}
                      </Span>
                    </Box>
                  </Grid>

                  <Grid item xs={4}>
                    <Box sx={styles.whiteCard}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Approved Applications (0)
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        UGX
                        {scoreResult?.Credit_Applications
                          ?.Approved_Applications || "---"}
                      </Span>
                    </Box>
                  </Grid>

                  <Grid item xs={4}>
                    <Box sx={styles.whiteCard}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Rejected Applications (0)
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        UGX
                        {scoreResult?.Credit_Applications
                          ?.Rejected_Applications || "---"}
                      </Span>
                    </Box>
                  </Grid>

                  <Grid item xs={4}>
                    <Box sx={styles.whiteCard}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Cancelled Applications (0)
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        UGX
                        {scoreResult?.Credit_Applications
                          ?.Cancelled_Applications || "---"}
                      </Span>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </CollapsibleSection>
          </Box>

          <Box marginBottom={2}>
            <CollapsibleSection title="Credit Accounts">
              <Box
                sx={[
                  styles.container,
                  {
                    marginX: "-1rem",
                    marginBottom: "-1rem",
                    transition: "all 0.3s",
                  },
                ]}
              >
                <Grid container rowSpacing={2.5}>
                  <Grid item xs={6}>
                    <H6 marginBottom={0} sx={styles.vlabelText}>
                      Total Accounts
                    </H6>
                    <Span sx={styles.vlabelValue}>
                      {scoreResult?.Credit_Accounts?.Total_Accounts || "---"}
                    </Span>
                  </Grid>
                  <Grid item xs={6}>
                    <H6 marginBottom={0} sx={styles.vlabelText}>
                      Total Accounts_12 months
                    </H6>
                    <Span sx={styles.vlabelValue}>
                      {scoreResult?.Credit_Accounts?.Total_Accounts_12Months ||
                        "---"}
                    </Span>
                  </Grid>
                  <Grid item xs={6}>
                    <H6 marginBottom={0} sx={styles.vlabelText}>
                      Open Accounts
                    </H6>
                    <Span sx={styles.vlabelValue}>
                      {scoreResult?.Credit_Accounts?.Open_Accounts || "---"}
                    </Span>
                  </Grid>
                  <Grid item xs={6}>
                    <H6 marginBottom={0} sx={styles.vlabelText}>
                      Open Accounts_ 12 months
                    </H6>
                    <Span sx={styles.vlabelValue}>
                      {scoreResult?.Credit_Accounts?.Open_Accounts_12Months ||
                        "---"}
                    </Span>
                  </Grid>
                  <Grid item xs={6}>
                    <H6 marginBottom={0} sx={styles.vlabelText}>
                      Closed Accounts
                    </H6>
                    <Span sx={styles.vlabelValue}>
                      {scoreResult?.Credit_Accounts?.Closed_Accounts || "---"}
                    </Span>
                  </Grid>
                  <Grid item xs={6}>
                    <H6 marginBottom={0} sx={styles.vlabelText}>
                      Closed Accounts_ 12 months
                    </H6>
                    <Span sx={styles.vlabelValue}>
                      {scoreResult?.Credit_Accounts?.Closed_Accounts_12Months ||
                        "---"}
                    </Span>
                  </Grid>
                  <Grid item xs={6}>
                    <H6 marginBottom={0} sx={styles.vlabelText}>
                      Adverse Accounts
                    </H6>
                    <Span sx={styles.vlabelValue}>
                      {scoreResult?.Credit_Accounts?.Adverse_Accounts || "---"}
                    </Span>
                  </Grid>
                  <Grid item xs={6}>
                    <H6 marginBottom={0} sx={styles.vlabelText}>
                      Adverse Accounts_ 12 months
                    </H6>
                    <Span sx={styles.vlabelValue}>
                      {scoreResult?.Credit_Accounts
                        ?.Adverse_Accounts_12Months || "---"}
                    </Span>
                  </Grid>
                  <Grid item xs={6}>
                    <H6 marginBottom={0} sx={styles.vlabelText}>
                      Worst Active days in Arrears
                    </H6>
                    <Span sx={styles.vlabelValue}>
                      {scoreResult?.Credit_Accounts
                        ?.Worst_Active_Days_in_arrears || "---"}
                    </Span>
                  </Grid>
                  <Grid item xs={6}>
                    <H6 marginBottom={0} sx={styles.vlabelText}>
                      Worst Active days in Arrears_ 12 months
                    </H6>
                    <Span sx={styles.vlabelValue}>
                      {scoreResult?.Credit_Accounts
                        ?.Worst_Days_in_arrears_12Months || "---"}
                    </Span>
                  </Grid>

                  {scoreResult?.Credit_Accounts?.Total_Value?.map(
                    (item: any, index: number) => (
                      <Grid item xs={12} key={`total-value-${index}`}>
                        <Box sx={styles.whiteCard}>
                          <H6>Total Value</H6>
                          <Grid container>
                            <Grid item xs={4}>
                              <H6 marginBottom={0} sx={styles.vlabelText}>
                                Loan Amount
                              </H6>
                              <Span sx={styles.vlabelValue}>
                                {item?.Loan_Amount || "---"}
                              </Span>
                            </Grid>
                            <Grid item xs={4}>
                              <H6 marginBottom={0} sx={styles.vlabelText}>
                                Loan Balance
                              </H6>
                              <Span sx={styles.vlabelValue}>
                                {item?.Loan_Balance || "---"}
                              </Span>
                            </Grid>
                            <Grid item xs={4}>
                              <H6 marginBottom={0} sx={styles.vlabelText}>
                                Amount Overdue
                              </H6>
                              <Span sx={styles.vlabelValue}>
                                {item?.Amount_Overdue || "---"}
                              </Span>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    ),
                  )}

                  {scoreResult?.Credit_Accounts?.Value_12Months?.map(
                    (item: any, index: number) => (
                      <Grid item xs={12} key={`account-${index}`}>
                        <Box sx={styles.whiteCard}>
                          <H6>Value in 12 Months</H6>
                          <Grid container>
                            <Grid item xs={4}>
                              <H6 marginBottom={0} sx={styles.vlabelText}>
                                Loan Amount_12 months
                              </H6>
                              <Span sx={styles.vlabelValue}>
                                {item?.Loan_Amount_12Months || "---"}
                              </Span>
                            </Grid>
                            <Grid item xs={4}>
                              <H6 marginBottom={0} sx={styles.vlabelText}>
                                Payment_12 months
                              </H6>
                              <Span sx={styles.vlabelValue}>
                                {item?.Payment_12Months || "---"}
                              </Span>
                            </Grid>
                            <Grid item xs={4}>
                              <H6 marginBottom={0} sx={styles.vlabelText}>
                                Loan Balance_12 months
                              </H6>
                              <Span sx={styles.vlabelValue}>
                                {item?.Loan_Balance_12Months || "---"}
                              </Span>
                            </Grid>
                            <Grid item xs={4}>
                              <H6 marginBottom={0} sx={styles.vlabelText}>
                                Amount Overdue_12 months{" "}
                              </H6>
                              <Span sx={styles.vlabelValue}>
                                {item?.Amount_Overdue_12Months || "---"}
                              </Span>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    ),
                  )}
                </Grid>
              </Box>
            </CollapsibleSection>
          </Box>

          <Box marginBottom={2}>
            <CollapsibleSection title="Collateral Material">
              <Box
                sx={[
                  styles.container,
                  {
                    marginX: "-1rem",
                    marginBottom: "-1rem",
                    transition: "all 0.3s",
                  },
                ]}
              >
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Box sx={styles.whiteCard}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Collateral Material (0)
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        UGX
                        {scoreResult?.Collateral_Material || "---"}
                      </Span>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </CollapsibleSection>
          </Box>

          <Box marginBottom={2}>
            <CollapsibleSection title="Collateral Guarantor">
              <Box
                sx={[
                  styles.container,
                  {
                    marginX: "-1rem",
                    marginBottom: "-1rem",
                    transition: "all 0.3s",
                  },
                ]}
              >
                {scoreResult?.Collateral_Guarantor?.map(
                  (item: any, index: number) => (
                    <Grid container spacing={1} key={`guarantor-${index} `}>
                      <Grid item xs={12}>
                        <H6>Accounts: 0</H6>
                      </Grid>

                      <Grid item xs={4}>
                        <Box sx={styles.whiteCard}>
                          <H6 marginBottom={0} sx={styles.vlabelText}>
                            Loan Amount
                          </H6>
                          <Span sx={styles.vlabelValue}>
                            UGX
                            {item?.Loan_Amount || "---"}
                          </Span>
                        </Box>
                      </Grid>

                      <Grid item xs={4}>
                        <Box sx={styles.whiteCard}>
                          <H6 marginBottom={0} sx={styles.vlabelText}>
                            Loan Balance
                          </H6>
                          <Span sx={styles.vlabelValue}>
                            UGX
                            {item?.Loan_Balance || "---"}
                          </Span>
                        </Box>
                      </Grid>
                    </Grid>
                  ),
                )}
              </Box>
            </CollapsibleSection>
          </Box>

          <Box marginBottom={2}>
            <CollapsibleSection title="Bounced Cheques">
              <Box
                sx={[
                  styles.container,
                  {
                    marginX: "-1rem",
                    marginBottom: "-1rem",
                    transition: "all 0.3s",
                  },
                ]}
              >
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Box sx={styles.whiteCard}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Bounced Cheques (0)
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        UGX
                        {scoreResult?.Bounced_Cheques || "---"}
                      </Span>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </CollapsibleSection>
          </Box>

          <Box marginBottom={2}>
            <CollapsibleSection title="Financial Fraud">
              <Box
                sx={[
                  styles.container,
                  {
                    marginX: "-1rem",
                    marginBottom: "-1rem",
                    transition: "all 0.3s",
                  },
                ]}
              >
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Box sx={styles.whiteCard}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Financial Fraud Cases (0)
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        UGX
                        {scoreResult?.Financial_Fraud || "---"}
                      </Span>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </CollapsibleSection>
          </Box>
        </Box>
      ) : (
        <Box sx={{ paddingBottom: 5 }}>
          <Box marginBottom={2} width={"100%"}>
            <FlexBox alignItems={"center"} justifyContent={"space-between"}>
              <H6 sx={styles.profileTitle}>Results</H6>
            </FlexBox>

            {hasMultipleProfiles() && (
              <List
                sx={styles.listContainer}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                {handleGetProfilesList().map((profile: any, index: number) => (
                  <ListItemButton
                    key={`formal-individual-${index}`}
                    onClick={() => handleGetProfileDetails(profile)}
                  >
                    <ListItemText primary={profile} />
                    <ArrowForwardIosIcon />
                  </ListItemButton>
                ))}
              </List>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    backgroundColor: "#EFEFEF",
    borderRadius: "0.5rem",
  },
  labelText: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#000000",
  },
  labelValue: {
    fontSize: "0.8rem",
    fontWeight: 400,
    color: "#5F6368",
    display: "block",
  },
  vlabelText: {
    fontSize: "0.8rem",
    fontWeight: 400,
    color: "#000000",
  },
  vlabelValue: {
    fontSize: "1rem",
    fontWeight: 500,
    color: "#5F6368",
    display: "block",
  },
  iconCircle: {
    height: "4rem",
    width: "4rem",
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  whiteCard: {
    height: "100%",
    padding: "1rem",
    backgroundColor: "#FFFFFF",
    borderRadius: "0.5rem",
    border: "1px solid #E5EAF2",
  },
  profileTitle: {
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: 1,
  },
  listContainer: {
    width: "100%",
    bgcolor: "background.paper",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    marginBottom: 2,
  },
};

export default ResultsCreditScore;
