import { Badge, IconButton } from "@mui/material";
import { FC, useEffect, useState } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useDrawerContext } from "../../../contexts/DrawerContext";
import DrawerBox from "../../UI/DrawerBox";
import WorkflowTaskShort from "../../../pages/WorkflowTasks/WorkflowTaskShort";
import { useAuth } from "../../../hooks/useAuth";

import { collection, onSnapshot } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { firebase } from "../../../utils/firebase";

const WorkflowTaskNotifications: FC = () => {
  const { user, fbtoken }: any = useAuth();
  const { showDrawer, setShowDrawer, setDrawerId } = useDrawerContext();
  const [taskCount, setTaskCount] = useState(0);

  useEffect(() => {
    let unsubscribe: any;

    const initFirebase = async () => {
      if (user?.uid) {
        unsubscribe = onSnapshot(
          collection(
            getFirestore(firebase),
            `${process.env.REACT_APP_FIRESTORE_NOTIFICATION_COLLECTION}/${user.uid}/${process.env.REACT_APP_FIRESTORE_SUB_COLLECTION_TASKS}`,
          ),
          (collection) => {
            setTaskCount(
              collection.docs
                .map((doc) => doc.data())
                .filter((doc) => doc.status === 0).length,
            );
          },
        );
      }
    };

    if (fbtoken) {
      initFirebase();
    }

    return () => unsubscribe && unsubscribe();
  }, [user?.uid, user.type, fbtoken]);

  const handleOpenDrawer = () => {
    setDrawerId("workflow-tasks");
    setShowDrawer({ ...showDrawer, ...{ right: true } });
  };

  const closeDrawer = () => {
    setShowDrawer({ ...showDrawer, ...{ right: false } });
  };

  return (
    <>
      <IconButton onClick={handleOpenDrawer}>
        <Badge color="error" badgeContent={taskCount}>
          <TaskAltIcon fontSize="medium" sx={{ color: "text.disabled" }} />
        </Badge>
      </IconButton>

      <DrawerBox
        minWidth={500}
        selectedDrawerId="workflow-tasks"
        anchor="right"
        onClose={() => {}}
      >
        <WorkflowTaskShort closeDrawer={closeDrawer} />
      </DrawerBox>
    </>
  );
};

export default WorkflowTaskNotifications;
