import { Badge, IconButton } from "@mui/material";
import { FC, useEffect, useState } from "react";
import NotificationsIcon from "../../../icons/NotificationsIcon";
import { useAuth } from "../../../hooks/useAuth";
import { useDrawerContext } from "../../../contexts/DrawerContext";
import DrawerBox from "../../UI/DrawerBox";
import { collection, onSnapshot } from "firebase/firestore";
import NotificationList from "../../../pages/Notifications/NotificationList";
import { firebase, firebaseAuthentication } from "../../../utils/firebase";
import { getFirestore } from "firebase/firestore";

const NotificationsPopover: FC = () => {
  const { user, fbtoken }: any = useAuth();
  const { showDrawer, setShowDrawer, setDrawerId } = useDrawerContext();
  const [count, setCount] = useState(0);

  useEffect(() => {
    let unsubscribe: any;

    const initFirebase = async () => {
      if (user?.uid) {
        unsubscribe = onSnapshot(
          collection(
            getFirestore(firebase),
            `${process.env.REACT_APP_FIRESTORE_NOTIFICATION_COLLECTION}/${user.uid}/${process.env.REACT_APP_FIRESTORE_SUB_COLLECTION_NOTIFICATIONS}`,
          ),
          (collection) => {
            setCount(
              collection.docs
                .map((doc) => doc.data())
                .filter((doc) => !doc.read).length,
            );
          },
        );
      }
    };

    if (fbtoken) {
      initFirebase();
    }

    return () => unsubscribe && unsubscribe();
  }, [user?.uid, fbtoken]);

  const handleOpenDrawer = () => {
    setDrawerId("notifications");
    setShowDrawer({ ...showDrawer, ...{ right: true } });
  };

  const closeDrawer = () => {
    setShowDrawer({ ...showDrawer, ...{ right: false } });
  };

  return (
    <>
      <IconButton onClick={handleOpenDrawer}>
        <Badge color="error" badgeContent={count}>
          <NotificationsIcon fontSize="small" sx={{ color: "text.disabled" }} />
        </Badge>
      </IconButton>

      <DrawerBox
        minWidth={400}
        selectedDrawerId="notifications"
        anchor="right"
        onClose={() => {}}
      >
        <NotificationList closeDrawer={closeDrawer} />
      </DrawerBox>
    </>
  );
};

export default NotificationsPopover;
