import jwtDecode from "jwt-decode";
import {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState,
  useRef,
} from "react";
import LoadingScreen from "../components/LoadingScreen";
import axiosInstance from "../api";
import axios from "axios";
import { API_BASE_URL } from "../api/constants";
import SessionMonitor from "../components/SessionMonitor";

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type AuthUser = null | Record<string, any>;

export type AuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  fbtoken?: string | null;
  showSessionPrompt: boolean;
  remainingSeconds: number;
};

enum Types {
  Init = "INIT",
  Update = "UPDATE",
  Login = "LOGIN",
  Logout = "LOGOUT",
  ShowSessionPrompt = "SHOW_SESSION_PROMPT",
  UpdateRemainingSeconds = "UPDATE_REMAINING_SECONDS",
}

type JWTAuthPayload = {
  [Types.Init]: {
    isAuthenticated: boolean;
    user: AuthUser;
    fbtoken: string | null;
    showSessionPrompt: boolean;
    remainingSeconds: number;
  };
  [Types.Logout]: undefined;
  [Types.Login]: { user: AuthUser };
  [Types.Update]: { user: AuthUser };
  [Types.ShowSessionPrompt]: { show: boolean };
  [Types.UpdateRemainingSeconds]: { seconds: number };
};

type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  user: null,
  isInitialized: false,
  isAuthenticated: false,
  fbtoken: null,
  showSessionPrompt: false,
  remainingSeconds: 0,
};

const isValidToken = (accessToken: string) => {
  if (!accessToken) return false;
  const decodedToken = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const getTokenExpiryTime = (accessToken: string): number => {
  if (!accessToken) return 0;
  const decodedToken = jwtDecode<{ exp: number }>(accessToken);
  return decodedToken.exp;
};

const setSession = (
  accessToken: string | null,
  refreshToken: string | null = null,
) => {
  if (accessToken) {
    localStorage.setItem("accessToken", btoa(accessToken));
    if (refreshToken) {
      localStorage.setItem("refreshToken", btoa(refreshToken));
    }
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case "INIT": {
      return {
        isInitialized: true,
        user: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
        fbtoken: action.payload.fbtoken,
        showSessionPrompt: action.payload.showSessionPrompt,
        remainingSeconds: action.payload.remainingSeconds,
      };
    }
    case "LOGIN": {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        showSessionPrompt: false,
      };
    }
    case "UPDATE": {
      return {
        ...state,
        user: action.payload.user,
      };
    }
    case Types.ShowSessionPrompt: {
      return {
        ...state,
        showSessionPrompt: action.payload.show,
      };
    }
    case Types.UpdateRemainingSeconds: {
      return {
        ...state,
        remainingSeconds: action.payload.seconds,
      };
    }
    default: {
      return state;
    }
  }
};

const AuthContext: any = createContext({
  ...initialState,
  method: "JWT",
  login: (email: string, password: string) => Promise.resolve(),
  logout: () => {},
  refreshSession: () => Promise.resolve(false),
});

// props type
type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [getUserDetails, setGetUserDetails] = useState(false);
  const [userClosedPrompt, setUserClosedPrompt] = useState(false);
  const sessionCheckInterval = useRef<ReturnType<typeof setInterval> | null>(
    null,
  );
  const expiryTimeRef = useRef<number>(0);

  const startSessionMonitoring = () => {
    if (sessionCheckInterval.current) {
      clearInterval(sessionCheckInterval.current);
    }

    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;

    const decodedToken = jwtDecode<{ exp: number }>(atob(accessToken));
    expiryTimeRef.current = decodedToken.exp;

    const checkSession = () => {
      try {
        const currentTime = Math.floor(Date.now() / 1000);
        const timeUntilExpiry = Math.max(
          0,
          expiryTimeRef.current - currentTime,
        );

        if (
          timeUntilExpiry <= 120 &&
          timeUntilExpiry > 0 &&
          !state.showSessionPrompt &&
          !userClosedPrompt
        ) {
          dispatch({
            type: Types.ShowSessionPrompt,
            payload: { show: true },
          });
          dispatch({
            type: Types.UpdateRemainingSeconds,
            payload: { seconds: timeUntilExpiry },
          });
        }

        if (timeUntilExpiry === 0) {
          logout();
          return;
        }

        if (state.showSessionPrompt) {
          dispatch({
            type: Types.UpdateRemainingSeconds,
            payload: { seconds: timeUntilExpiry },
          });
        }
      } catch (error) {}
    };

    sessionCheckInterval.current = setInterval(checkSession, 1000);
    checkSession();
  };

  const refreshSession = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) {
        return false;
      }

      const response = await axios.post(`${API_BASE_URL}token/refresh/`, {
        refresh: atob(refreshToken),
      });

      const { access }: any = response.data;
      setSession(access);

      const newExpiryTime = getTokenExpiryTime(access);
      expiryTimeRef.current = newExpiryTime;

      setUserClosedPrompt(false);

      dispatch({
        type: Types.ShowSessionPrompt,
        payload: { show: false },
      });

      startSessionMonitoring();

      return true;
    } catch (error) {
      logout();
      return false;
    }
  };

  const login = async (email: string, password: string) => {
    try {
      const response = await axios.post(`${API_BASE_URL}token/`, {
        email,
        password,
      });

      const { access, refresh }: any = response.data;
      setSession(access, refresh);

      dispatch({
        type: Types.Login,
        payload: { user: {} },
      });
      setGetUserDetails(true);

      startSessionMonitoring();
    } catch (error: any) {
      throw new Error(error.response.data.detail);
    }
  };

  const logout = () => {
    if (sessionCheckInterval.current) {
      clearInterval(sessionCheckInterval.current);
      sessionCheckInterval.current = null;
    }

    setSession(null);
    setGetUserDetails(false);
    dispatch({ type: Types.Logout });
  };

  const getUser = async (): Promise<any> => {
    try {
      const response = await axiosInstance.get(`/user/me/`);
      return response.data;
    } catch (error: any) {
      return {};
    }
  };

  const getFirebaseToken = async (): Promise<any> => {
    try {
      const response: any = await axiosInstance.get(`/user/firebase-auth-me/`);
      return response?.data?.token;
    } catch (error: any) {
      return {};
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(atob(accessToken))) {
          const user = await getUser();
          const fbtoken = await getFirebaseToken();
          const decodedToken = jwtDecode<{ exp: number }>(atob(accessToken));
          const timeUntilExpiry = Math.max(
            0,
            decodedToken.exp - Math.floor(Date.now() / 1000),
          );

          dispatch({
            type: Types.Init,
            payload: {
              user: await getUser(),
              fbtoken: await getFirebaseToken(),
              isAuthenticated: true,
              showSessionPrompt: false,
              remainingSeconds: timeUntilExpiry,
            },
          });
          startSessionMonitoring();
        } else {
          dispatch({
            type: Types.Init,
            payload: {
              user: null,
              isAuthenticated: false,
              fbtoken: null,
              showSessionPrompt: false,
              remainingSeconds: 0,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: Types.Init,
          payload: {
            user: null,
            isAuthenticated: false,
            fbtoken: null,
            showSessionPrompt: false,
            remainingSeconds: 0,
          },
        });
      }
    })();
  }, [getUserDetails]);

  useEffect(() => {
    return () => {
      if (sessionCheckInterval.current) {
        clearInterval(sessionCheckInterval.current);
      }
    };
  }, []);

  /**
   * Closes the session prompt dialog
   */
  const closeSessionPrompt = () => {
    setUserClosedPrompt(true);

    dispatch({
      type: Types.ShowSessionPrompt,
      payload: { show: false },
    });
  };

  if (!state.isInitialized) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        refreshSession,
        dispatch,
        closeSessionPrompt,
      }}
    >
      {children}
      <SessionMonitor
        open={state.showSessionPrompt}
        onStayLoggedIn={refreshSession}
        onLogout={logout}
        initialSeconds={state.remainingSeconds}
        onClose={closeSessionPrompt}
      />
    </AuthContext.Provider>
  );
};

export default AuthContext;
