import { FC, useEffect, useState } from "react";
import TransactionReversal from "./TransactionReversal";
import LoanWriteOff from "./LoanWriteOff";
import LoanDisbursement from "./LoanDisbursement";
import LoanApproval from "./LoanApproval";
import SharePurchaseReversal from "./SharePurchase";
import ReceivableReversal from "./ReceivableReversal";
import PayableReversal from "./PayableReversal";
import LoanTopup from "./LoanTopup";
import LoanDisbursementReversal from "./LoanDisbursementReversal";
import PhysicalAssetReversal from "./PhysicalAssetReversal";
import NonPhysicalAssetReversal from "./NonPhysicalAssetReversal";
import _ from "lodash";
import { WorkflowTaskService } from "../services/WorkflowTask.service";
import ProgressIndicator from "../../../components/UI/ProgressIndicator";
import FlexBox from "../../../components/FlexBox";

interface ITaskDetailsInfo {
  taskId: string;
  hasDetails?: boolean;
  details?: any;
}

const TaskDetailsInfo: FC<ITaskDetailsInfo> = ({
  taskId,
  hasDetails,
  details,
}) => {
  const [taskDetails, setTaskDetails] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getTaskDetails = async () => {
      setLoading(true);
      try {
        const results = await WorkflowTaskService.getTaskDetails(taskId);
        setTaskDetails(results);
      } catch (error) {
        setTaskDetails(null);
      } finally {
        setLoading(false);
      }
    };

    if (taskId && !hasDetails) {
      getTaskDetails();
    }
  }, [taskId, hasDetails]);

  useEffect(() => {
    if (hasDetails && details) {
      setTaskDetails(details);
    }
  }, [hasDetails, details]);

  if (loading) {
    return (
      <FlexBox alignItems={"center"} justifyContent={"center"}>
        <ProgressIndicator size={20} />
      </FlexBox>
    );
  }

  switch (taskDetails?.request?.process) {
    case "transaction_reversal":
      return (
        <TransactionReversal entityDetails={taskDetails?.request?.entity} />
      );
    case "loan_writeoff":
      return <LoanWriteOff entityDetails={taskDetails?.request?.entity} />;
    case "loan_disbursement":
      return <LoanDisbursement entityDetails={taskDetails?.request?.entity} />;
    case "loan_disbursement_reversal":
      return (
        <LoanDisbursementReversal
          entityDetails={taskDetails?.request?.entity}
        />
      );
    case "loan_approval":
      return <LoanApproval entityDetails={taskDetails?.request?.entity} />;
    case "loan_topup":
      return (
        <LoanTopup
          entityDetails={taskDetails?.request.entity}
          extraData={taskDetails?.request?.extras}
        />
      );
    case "share_purchase_reversal":
      return (
        <SharePurchaseReversal entityDetails={taskDetails?.request?.entity} />
      );
    case "receivable_reversal":
      return (
        <ReceivableReversal entityDetails={taskDetails?.request?.entity} />
      );
    case "payable_reversal":
      return <PayableReversal entityDetails={taskDetails?.request?.entity} />;
    case "asset_purchase_reversal":
      return _.has(taskDetails?.request?.entity, "warranty_provider_name") &&
        !_.has(taskDetails?.request?.entity, "yield_to_maturity") ? (
        <PhysicalAssetReversal entityDetails={taskDetails?.request?.entity} />
      ) : (
        <NonPhysicalAssetReversal
          entityDetails={taskDetails?.request?.entity}
        />
      );
    default:
      return <></>;
  }
};

export default TaskDetailsInfo;
