import * as React from "react";
import { debounce } from "lodash";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { FC, useEffect } from "react";
import { ukoTheme } from "../../../theme";
import { deepDerefrencer, IFormField } from "../../../utils/form_factory";
import uniqueId from "../../../utils/generateId";
import { FormControl } from "@mui/material";
import { Small } from "../../Typography";

interface IFormSelectSearchProps extends Partial<IFormField> {
  name: string;
  label: string;
  type?: string;
  parseFilter?: (...params: any) => any;
  filterValues?: any;
  dataFetcher?: (
    search: string,
    setData: (results: any) => void,
    extraFilters?: any,
  ) => Promise<any> | any;
  selector?: {
    value: (option: any) => any;
    label: (option: any) => any;
  };
  formControl: any;
  isDisabled?: boolean;
}

const FormAsyncSelectSearch: FC<IFormSelectSearchProps> = (
  props: IFormSelectSearchProps,
) => {
  const theme = ukoTheme();

  const [searchText, setSearchText] = React.useState("");
  const [optionsList, setOptionsList] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);

  const isFieldRequired = (fieldName: string) => {
    return String(props.formControl.getFieldMeta(fieldName)?.error).match(
      /required/gi,
    )
      ? true
      : false;
  };

  useEffect(() => {
    const debounceSearch = debounce(async () => {
      const filters = props.parseFilter ? props.parseFilter() : {};
      if (props.dataFetcher) {
        setLoading(true);
        await props.dataFetcher(searchText, handleOptionList, { ...filters });
        setLoading(false);
      }
    }, 500);

    if (searchText) {
      debounceSearch();
    }

    return () => {
      debounceSearch.cancel();
    };
  }, [searchText]);

  const handleOptionList = (options: any[]) => {
    setOptionsList((prev: any[]) => {
      return [
        deepDerefrencer(props.formControl.values, props.name),
        ...options,
      ];
    });
  };

  return (
    <FormControl fullWidth>
      <Autocomplete
        ref={props.ref}
        id={uniqueId()}
        onChange={(_, newValue: any) => {
          props.formControl.getFieldProps(props.name).onChange({
            target: {
              name: props.name,
              value: newValue,
            },
          });
        }}
        getOptionLabel={(option) => {
          const result = props.selector?.label(option) || "";
          return result;
        }}
        disabled={props.isDisabled}
        options={optionsList}
        loading={loading}
        onBlur={props.formControl.getFieldProps(props.name).onBlur}
        value={deepDerefrencer(props.formControl.values, props.name)}
        defaultValue={deepDerefrencer(props.formControl.values, props.name)}
        filterSelectedOptions={false}
        renderInput={(params) => (
          <TextField
            sx={[
              styles.customStyles(
                theme,
                isFieldRequired(props.name)
                  ? theme.palette.primary.main
                  : undefined,
              ),
            ]}
            {...params}
            error={Boolean(
              deepDerefrencer(props.formControl.touched, props.name) &&
                deepDerefrencer(props.formControl.errors, props.name),
            )}
            label={props.label}
            InputProps={{
              ...params.InputProps,
              value: searchText,
              onChange: (e) => setSearchText(e.target.value),
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      {Boolean(
        deepDerefrencer(props.formControl.touched, props.name) &&
          deepDerefrencer(props.formControl.errors, props.name),
      ) && (
        <Small
          color="error.main"
          fontSize="0.75rem"
          fontWeight={"normal"}
          marginTop={1}
        >
          {deepDerefrencer(props.formControl.errors, props.name)}
        </Small>
      )}
    </FormControl>
  );
};

const styles = {
  customStyles: (theme: any, outlineColor?: string) => ({
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      border: "2px solid",
      borderColor: outlineColor
        ? outlineColor
        : theme.palette.mode === "light"
        ? theme.palette.secondary[300]
        : theme.palette.divider,
    },

    "& input[type='file'].MuiInputBase-input": {
      marginLeft: "120px",
    },
  }),
};

export default FormAsyncSelectSearch;

// TODO: to be using for highlighting the border of the input field which is required

// .css-1xu8b25-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-notchedOutline {
//   border-radius: 8px;
//   border: 2px solid;
//   border-color: #E5EAF2;
//   border-left: 5px solid #f67979;
// }
