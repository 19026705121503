import { FC, Fragment, useEffect, useState } from "react";
import moment from "moment";
import {
  Box,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  Theme,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import ProgressPaper from "../../components/UI/ProgressPaper";
import { H4, H5, Small } from "../../components/Typography";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import StatusBtn from "../../components/UI/StatusBtn";
import { NOTIFICATION_STATUS } from "../../constants/notifications";
import NotificationSettings from "./NotificationSettings";
import { NotificationService } from "./services/Notification.service";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import { firebase, firebaseAuthentication } from "../../utils/firebase";
import { collection, onSnapshot, getFirestore } from "firebase/firestore";
import { useAuth } from "../../hooks/useAuth";
import NotificationActionBtn, {
  INotificationAction,
} from "./NotificationActionBtn";
import { ukoTheme } from "../../theme";
import TruncatedParagraph from "../../components/UI/TruncatedParagraph";
import { toast } from "react-hot-toast";
import ProgressIndicator from "../../components/UI/ProgressIndicator";
import DoneAllIcon from "@mui/icons-material/DoneAll";

interface IWorkflowTaskShortProps {
  closeDrawer: () => void;
}

const NotificationList: FC<IWorkflowTaskShortProps> = ({ closeDrawer }) => {
  const theme = ukoTheme();
  const auth: any = useAuth();
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState<boolean>(false);
  const [markingAll, setMarkingAll] = useState<boolean>(false);
  const [marking, setMarking] = useState<boolean>(false);
  const [showNotificationSettings, setShowNotificationSettings] =
    useState<boolean>(false);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [noticationTab, setNotificationTab] = useState<number>(3);

  const handleShowSettings = () => {
    setShowNotificationSettings(true);
  };

  const handleCloseSettings = () => {
    setShowNotificationSettings(false);
  };

  const handleMarkAllAsRead = async () => {
    setMarkingAll(true);
    try {
      await NotificationService.markAllAsRead({});
      setMarkingAll(false);
    } catch (error) {
      setMarkingAll(false);
      toast.error("Failed to mark all as read");
    }
  };

  const handleMarkSingleAsRead = async (notification: any) => {
    setMarking(true);
    try {
      await NotificationService.markSingleAsRead(
        { read: true },
        notification.id,
      );
      setMarking(false);
    } catch (error) {
      setMarking(false);
      toast.error("Failed to mark as read");
    }
  };

  const tableActions: INotificationAction[] = [
    {
      label: "Mark as read",
      icon: <MarkEmailUnreadIcon color="inherit" />,
      onClick: (notification) => handleMarkSingleAsRead(notification),
    },
  ];

  useEffect(() => {
    let unsubscribe: any;

    const initFirebase = async () => {
      if (auth?.user?.uid) {
        unsubscribe = onSnapshot(
          collection(
            getFirestore(firebase),
            `${process.env.REACT_APP_FIRESTORE_NOTIFICATION_COLLECTION}/${auth.user.uid}/${process.env.REACT_APP_FIRESTORE_SUB_COLLECTION_NOTIFICATIONS}`,
          ),
          (collection) => {
            setNotifications(
              collection.docs
                .map((doc) => doc.data())
                .filter((notification: any) => {
                  if (noticationTab == 0) {
                    return notification;
                  } else if (noticationTab === 2) {
                    return notification.read;
                  } else {
                    return !notification.read;
                  }
                })
                .sort((notificationA: any, notificationB: any) =>
                  notificationA?.createdat > notificationB.createdat ? -1 : 1,
                ),
            );
          },
        );
      }
    };

    if (auth?.fbtoken) {
      initFirebase();
    }
    return () => unsubscribe && unsubscribe();
  }, [auth?.user?.uid, noticationTab, auth?.fbtoken]);

  return (
    <Box sx={{ height: "100%", display: "flex", overflowX: "hidden" }}>
      <Box
        sx={{
          ...styles.notificationContainer,
          display: showNotificationSettings ? "none" : "block",
          "&": {
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          },
        }}
      >
        <ProgressPaper
          extraStyles={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden !important",
          }}
          loading={loading}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.background.paper,
              position: "sticky",
              top: 0,
              zIndex: 100,
            }}
          >
            <Box sx={{ ...styles.workflow, ...styles.displayFlex }}>
              <H4 fontWeight={"bold"}>Notifications</H4>
              <Button
                onClick={closeDrawer}
                color="inherit"
                variant={"contained"}
                sx={{ ...styles.closeButton, marginLeft: "auto" }}
              >
                <CloseIcon />
              </Button>
            </Box>
            <Box sx={{ ...styles.workflow, ...styles.displayFlex, gap: 1.5 }}>
              {NOTIFICATION_STATUS.map((status, index) => (
                <Chip
                  key={index}
                  color={noticationTab === status.value ? "primary" : "default"}
                  label={status.name}
                  onClick={() => {
                    setNotificationTab(status.value);
                  }}
                />
              ))}

              <Tooltip arrow={true} placement="top" title="Mark all as read">
                <Button
                  size="small"
                  onClick={handleMarkAllAsRead}
                  color="primary"
                  variant={"contained"}
                  sx={{ paddingX: 1.2, paddingY: 0.8, marginLeft: "1rem" }}
                >
                  <DoneAllIcon
                    fontSize="small"
                    sx={{ marginRight: downSm ? "" : "0.5rem" }}
                  />
                  {downSm ? null : "Mark all as read"}

                  {markingAll && (
                    <ProgressIndicator
                      color="inherit"
                      size={16}
                      sx={{ marginLeft: 1, color: "#fff" }}
                    />
                  )}
                </Button>
              </Tooltip>

              <Tooltip
                arrow={true}
                placement="top"
                title="Notification Settings"
              >
                <Button
                  onClick={handleShowSettings}
                  color="inherit"
                  variant={"contained"}
                  sx={{ ...styles.closeButton, marginLeft: "auto" }}
                >
                  <SettingsIcon />
                </Button>
              </Tooltip>
            </Box>
          </Box>

          <Box sx={{ width: "100%", height: "100%" }}>
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                overflowY: "auto",
                height: "100%",
              }}
            >
              {notifications.map((notification, index) => (
                <Fragment key={`task-${index}`}>
                  <ListItem alignItems="flex-start">
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>
                            <H5
                              color={
                                !notification.read
                                  ? theme.palette.primary.main
                                  : theme.palette.secondary.dark
                              }
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                              }}
                            >
                              {notification.actor.name}
                            </H5>
                            <Box sx={{ display: "inline", marginLeft: 1 }}>
                              <StatusBtn title={notification.verb} type={4} />
                            </Box>
                          </Box>
                          <Box>
                            <Small
                              color={
                                !notification.read
                                  ? theme.palette.primary.main
                                  : theme.palette.text.disabled
                              }
                            >
                              {moment(notification?.createdat).calendar()}
                            </Small>
                          </Box>
                        </Box>
                        <Box>
                          <TruncatedParagraph
                            text={notification.description}
                            maxLines={1}
                          />
                        </Box>
                      </Box>
                      {!notification.read && (
                        <Box sx={{ marginLeft: "1rem" }}>
                          <NotificationActionBtn
                            key={index}
                            actions={tableActions}
                            metaData={notification}
                          />
                        </Box>
                      )}
                    </Box>
                  </ListItem>
                  <Divider sx={{ marginY: 1 }} />
                </Fragment>
              ))}

              <ListItem alignItems="flex-start" sx={{ padding: 6 }}>
                <Box sx={{ paddingY: 5 }}></Box>
              </ListItem>
            </List>
          </Box>
        </ProgressPaper>
      </Box>
      <Box
        sx={[
          styles.notificationSettings,
          showNotificationSettings ? styles.showSettings : styles.hideSettings,
          {
            "&": {
              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },
            },
          },
        ]}
      >
        <NotificationSettings
          handleClose={closeDrawer}
          goBack={handleCloseSettings}
        />
      </Box>
    </Box>
  );
};

const styles = {
  notificationContainer: {
    height: "100%",
    width: 600,
    borderRight: "1px solid #e0e0e0",
    transition: "width 0.5s ease-in-out",
  },
  btnGath: {
    height: "auto !important",
    padding: "0.1rem 0.5rem !important",
    minWidth: "24px !important",
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
  },
  workflowTitle: {
    justifyContent: "space-between",
  },
  workflow: {
    padding: 2,
    borderBottom: "1px solid #e0e0e0",
  },
  workflowLevels: {
    padding: 2,
    borderBottom: "1px solid #e0e0e0",
  },
  closeButton: {
    borderRadius: "50%",
    padding: "0 !important",
    width: "2rem",
    height: "2rem",
    minWidth: "2rem",
    backgroundColor: "white",

    "&:hover": {
      backgroundColor: "inherit.main",
      color: "black",
    },
  },
  field: {
    display: "inline-block",
  },
  fieldLabel: {
    width: "25%",
    fontWeight: "500",
  },
  levelsContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    "& > ul": {
      width: "100%",
      margin: 0,
      padding: 0,
    },
    "& > ul > li": {
      position: "relative",
      width: "100%",
      listStyle: "none",
      display: "flex",
      gap: "1rem",
      alignItems: "flex-start",
    },
    "& > ul > li > span": {
      height: "2rem",
      width: "2rem",
      borderRadius: "50%",
      backgroundColor: "#eff0ef",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& > ul > li > div": {
      display: "flex",
      justifyContent: "space-between",
      borderRadius: "0.5rem",
      padding: "0 0.5rem 0.5rem 0.5rem",
      width: "calc(100% - 3rem)",
    },
    "& > ul > li + li": {
      marginTop: "1rem",
    },
    "& > ul > li::before": {
      content: '""',
      height: "100%",
      width: "2px",
      backgroundColor: "#eff0ef",
      position: "absolute",
      left: "0.9rem",
      marginTop: "2rem",
      marginBottom: "2rem",
    },
    "& > ul > li:last-child::before": {
      display: "none",
    },
  },
  levelStatus: {
    height: "2rem",
    width: "2rem",
    borderRadius: "50%",
    backgroundColor: "#eff0ef",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
  },
  clickable: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#eff0ef",
    },
  },
  taskContainer: {
    width: "100%",
    backgroundColor: "#eff0ef",
    borderRadius: "8px",
  },
  taskHeader: {
    padding: "0.5rem",
  },
  taskBody: {
    padding: "0.5rem",
    borderTop: "2px solid #ffffff",
    width: "100%",
  },
  notificationSettings: {
    height: "100%",
    background: "#ffffff",
    width: 600,
  },
  hideSettings: {
    display: "none",
  },
  showSettings: {
    display: "block",
  },
};

export default NotificationList;
